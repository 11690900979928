import { APIClient } from "../utils/apiHelper";

const { get, post } = new APIClient();

export default {
  signUp: (signUpData) => post(`/auth/signup`, signUpData),
  login: (loginData) => post(`/oauth/login`, loginData),
  logined: (code) => post(`/oauth/logined`, { code }),
  isExistUserId: (userId) => get(`/auth/isExistUserId?userId=${userId}`),
  isExistEmail: (email) => get(`/auth/isExistEmail?email=${email}`),
  authMe: () => get(`/oauth/me`),
};
