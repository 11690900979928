/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import { Provider, rootStore } from "models";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

// Material Kit 2 React routes
// import routes from "routes";
import SignInPage from "layouts/pages/authentication/sign-in";
import { observer } from "mobx-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignOut from "pages/LandingPages/SignIn/signOut";

const App = observer(() => {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  return (
    <Provider value={rootStore}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {/* {getRoutes(routes)} */}
          <Route path="/login" element={<SignInPage />} />
          <Route path="/sign-out" element={<SignOut />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
        <ToastContainer style={{ fontSize: 14 }} />
      </ThemeProvider>
    </Provider>
  );
});

export default App;
