let host =
  window.location.host.indexOf("localhost") > -1 || window.location.host.indexOf("127.0.0.1") > -1
    ? "local"
    : window.location.host.replace("sso.ejboo.com", "");
if (!host) host = "prod";
// host = 'prod'
console.log("host", host);

const config = {
  local: {
    CLIENT_ID: "kr.co.swis",
    API_URL: "http://localhost:11091/api/v1",
    KAKAO_API_KEY: "1bfb303c2ccc13f402f8e289c9b92b8b",
    NAVER_API_KEY: "V11kc2eeuYNkWde6p5H8",
    NAVER_SECRET: "D5EU53Ni8d",
    REDIRECT_URI: "http://localhost:11091",
  },
  prod: {
    CLIENT_ID: "kr.co.swis",
    API_URL: "https://sso.ejboo.com/api/v1",
    KAKAO_API_KEY: "1bfb303c2ccc13f402f8e289c9b92b8b",
    NAVER_API_KEY: "V11kc2eeuYNkWde6p5H8",
    NAVER_SECRET: "D5EU53Ni8d",
    REDIRECT_URI: "https://sso.ejboo.com",
  },
};

export default config[host];
