import { types } from "mobx-state-tree";
import { industry, jobGroup } from "../utils/industry";

export const User = types
  .model("User", {
    id: types.maybe(types.integer),
    userId: types.maybe(types.string),
    type: types.maybe(types.string),
    email: types.maybe(types.string),
    name: types.maybe(types.string),
    gender: types.maybe(types.string),
    birthYear: types.maybe(types.integer),
    industry: types.maybe(types.string),
    jobGroup: types.maybe(types.string),
    agency: types.maybe(types.string),
    rank: types.maybe(types.string),
    profile: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    updatedAt: types.maybe(types.string),
  })
  .views((self) => ({
    get me() {
      return {
        ...self,
      };
    },
  }))
  .actions((self) => ({
    async setData(data) {
      console.log("setData", data);
      self.id = data?.id || 0;
      self.userId = data?.userId || "";
      self.type = data?.type || "";
      self.email = data?.email || "";
      self.name = data?.name || "";
      self.gender = data?.gender || "";
      self.birthYear = data?.birthYear || 0;
      self.industry = data?.industry ? industry[data?.industry] : "";
      self.jobGroup =
        data?.industry && data?.jobGroup ? jobGroup[data?.industry][data?.jobGroup] : "";
      self.agency = data?.agency || "";
      self.rank = data?.rank || "";
      self.profile = data?.profile || "";
      self.createdAt = data?.createdAt || "";
      self.updatedAt = data?.updatedAt || "";
    },
    async logout() {
      self.id = 0;
      self.userId = "";
      self.type = "";
      self.email = "";
      self.name = "";
      self.gender = "";
      self.birthYear = 0;
      self.industry = "";
      self.jobGroup = "";
      self.agency = "";
      self.rank = "";
      self.profile = "";
      self.createdAt = "";
      self.updatedAt = "";
    },
  }));

export default User;
